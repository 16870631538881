@import '../src/assets/colors/Colors.scss';
@import 'react-quill/dist/quill.snow.css';
@import url('https://fonts.googleapis.com/css2?family=Inter;700&display=swap');

body {
    font-family: inter;
    font-weight: 400;
    font-style: normal;
}

.App {
    text-align: center;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important;
}

.ant-tabs-ink-bar {
    background: #000 !important;
}
