@import '../colors/Colors.scss';

body {
    margin: 0 !important;
    font-family: 'inter' !important;
}
.sider-nav {
    overflow: visible;
    height: 100vh;
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;

    & .collapse-button,
    .collapse-button:hover {
        width: 14px;
        height: 66px;
        position: absolute;
        top: calc(50% - 66px / 2);
        right: -14px;
        background-color: transparent;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        color: $primary;
        border: 0;
        padding: 0;
        border-radius: 0;
        filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.15));
    }
}

.site-layout {
    transition: all 0.2s;

    & .site-layout-background {
        &.header {
            padding: 0 36px 0 0;
            margin-left: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: sticky;
            top: 0;
            z-index: 2;

            & .header-title {
                border-left: $primary 4px solid;
                height: 40px;
                font-size: 24px;
                padding-left: 26px;
                display: flex;
                flex-direction: row;
                align-items: center;

                & .header-title-text {
                    font-weight: bold;
                }
            }

            & .user-icon {
                font-size: 20px;
                cursor: pointer;
            }
        }

        background: $white;
        box-shadow: 0 3px 3px 1px rgb(0 0 0 / 5%);
    }

    & .content {
        margin: 0 16px;

        > div {
            margin: 16px 0;
            padding: 24px;
            min-height: 100%;
        }
    }

    & .footer {
        text-align: center;
    }
}

/* // Custom ant sider menu */
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $primary;
    color: $white;
}

li.ant-menu-item.ant-menu-item-selected > span.ant-menu-item-icon {
    color: $white;
}

.ant-layout .ant-layout-sider-light .ant-layout-sider-trigger {
    background-color: var(--primary--lighter-2);
    color: var(--primary--darker);
}

.ant-menu .ant-menu-item {
    border-radius: 0;
}

.ant-menu-vertical .ant-menu-item {
    margin: 0;
    margin-bottom: 12px;
    width: 100%;
}

.ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: none;
}

.ant-tabs-tab-active {
    font-weight: bold;
}

.disabled-overlay {
    background-color: rgba(0, 0, 0, 0.5); 
    pointer-events: none; 
}

.custom-form-upload .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container {
    width: 500px;
    transition: opacity 0.3s, height 0.3s;
}
