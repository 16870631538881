.navigate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    & .navigation {
        & .logo {
            height: 32px;
            margin: 30px 16px;
        }

        & .menu {
            // height - logo - user_profile
            height: calc(100vh - 92px - 198px);
            overflow-y: scroll;
        }
    }

    & .user-profile {
        margin-top: 30px;

        & .user-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100%;
            padding: 16px 8px;
            background: rgba(204, 227, 224, 0.3);
            transition: all 0.2s;

            &.dark {
                background: var(--primary--lighter-3);
            }

            & .user-image {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: var(--primary);
            }

            & .user-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                overflow: hidden;
                gap: 2px;
                width: 100%;

                & .user-name {
                    width: 100%;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1;
                    color: var(--primary--darker);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                & .user-role {
                    width: 100%;
                    font-size: 12px;
                    line-height: 1;
                    color: var(--primary--lighter-1);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            & .logout-button {
                font-size: 16px;
                color: var(--primary);
                padding-right: 6px;
                cursor: pointer;
            }
        }

        & .setting-menu {
            &
                .ant-menu-light:not(.ant-menu-horizontal)
                .ant-menu-item:not(.ant-menu-item-selected):active,
            .ant-menu-item.ant-menu-item-active:hover,
            .ant-menu-dark:not(.ant-menu-horizontal)
                .ant-menu-item:not(.ant-menu-item-selected):active {
                background-color: transparent;
                cursor: default;
            }

            & .setting-pane {
                width: 144px;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

.sider-nav {
    overflow: visible;
    height: 100vh;
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;

    & .collapse-button,
    .collapse-button:hover {
        width: 14px;
        height: 66px;
        position: absolute;
        top: calc(50% - 66px / 2);
        right: -14px;
        background-color: transparent;
        background-image: url('../assets/images/collapse-button.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        color: var(--primary);
        border: 0;
        padding: 0;
        border-radius: 0;
        filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.15));
    }
}
