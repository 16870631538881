/* styles.css */

$white: #ffffff !default;
$gray-100: #f5f8fa !default;
$gray-200: #eff2f5 !default;
$gray-300: #e4e6ef !default;
$gray-400: #b5b5c3 !default;
$gray-500: #a1a5b7 !default;
$gray-600: #7e8299 !default;
$gray-700: #5e6278 !default;
$gray-800: #3f4254 !default;
$gray-900: #181c32 !default;
$black: #000000 !default;
$text-muted: $gray-500 !default;

$grays: (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
) !default;

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$colors: (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'gray': $gray-600,
) !default;

$primary: #1677ff !default;
$primary-active: #999999 !default;
$primary-light: #cccccc !default;
$primary-inverse: #f4f4f4 !default;

$secondary: #ffffff !default;
$secondary-active: $gray-400 !default;
$secondary-light: $gray-100 !default;
$secondary-inverse: $gray-800 !default;

$success: #52c41a !default;
$success-hover: #73d13d !default;
$success-active: #389e0d !default;
$success-light: #52c41a !default;
$success-inverse: $white !default;
$success-deprecated-background: #f6ffed !default;
$success-deprecated-border: #b7eb8f !default;

$info: #1890ff !default;
$info-hover: #40a9ff;
$info-active: #096dd9 !default;
$info-light: #eee5ff !default;
$info-inverse: $white !default;
$info-deprecated-background: #e6f7ff;
$info-deprecated-border: #91d5ff;

$warning: #faad14 !default;
$warning-hover: #ffc53d !default;
$warning-active: #d48806 !default;
$warning-light: #fff4de !default;
$warning-inverse: $white !default;
$warning-deprecated-background: #fffbe6 !default;
$warning-deprecated-border: #ffe58f !default;

$danger: #ff4d4f !default;
$danger-hover: #ff7875 !default;
$danger-active: #d9363e !default;
$danger-light: #ffe2e5 !default;
$danger-inverse: $white !default;
$danger-deprecated--background: #fff2f0 !default;
$danger-deprecated--border: #ffccc7 !default;

$white: $white !default;
$white-active: $gray-100 !default;
$white-inverse: $gray-600 !default;

$navbar-color-background: $secondary !default;
$navbar-color-text: $primary !default;

$footer-color-background: $primary !default;
$footer-color-text: $secondary !default;

$theme-colors: (
    'white': $white,
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
) !default;

$theme-active-colors: (
    'white': $white-active,
    'primary': $primary-active,
    'secondary': $secondary-active,
    'success': $success-active,
    'info': $info-active,
    'warning': $warning-active,
    'danger': $danger-active,
) !default;

@font-face {
    font-family: inter;
    src: url(../fonts/Inter/Inter-Regular.ttf);
}

.inter {
    font-family: inter;
}
